import React from 'react';

import Layout from '../../components/motorrad/layout';
import SEO from '../../components/motorrad/seo';
import FAQ from '../../components/faq/motorradfaq';

import faqs from '../../components/faq/motorrad-faqs';

const SchoolFAQPage = () => {
  return (
    <Layout>
      <SEO title="BMW U.S. Rider Academy | Frequently Asked Questions"  description="From the right riding gear to what you can expect when you get here, our FAQ keeps you in the know so that you're ready to go!" />
      <div className="faq-page deliveryfaq">
        <section className="hero__header">
          <div className="container">
            <div className="hero__header__content">
              <h1>MOTORRAD FREQUENTLY ASKED QUESTIONS</h1>
              <p>Listed below are some common questions regarding riding programs offered by BMW. For additional help, please call us at <a href="tel:888-345-4269">888-345-4269.</a></p>
            </div>
          </div>
        </section>
        <section className="faq">
          <div className="container">
            <FAQ faqs={faqs} />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default SchoolFAQPage;
